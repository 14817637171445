<template>
  <RevCard class="p-24">
    <div v-for="item in formattedItems" :key="item.id" class="pt-24 first:pt-0">
      <div class="flex" data-qa="product">
        <div class="relative">
          <div
            class="border-static-default-low rounded-sm flex h-40 w-40 items-center justify-center border"
          >
            <RevIllustration
              :alt="item.title"
              class="h-32 w-32"
              :height="32"
              :src="item.image"
              :width="32"
            />
          </div>
          <div
            v-if="item.quantity"
            class="bg-static-default-low border-static-default-low text-static-default-low shadow-long caption absolute -right-8 -top-8 rounded-[6px] border px-[2px]"
          >
            {{ item.quantity }}
          </div>
        </div>

        <div class="ml-24 w-full">
          <div class="space-y-8">
            <div class="flex justify-between gap-24">
              <h2 class="body-2-bold" data-qa="product-name">
                {{ item.title }}
              </h2>
              <div class="body-2" data-qa="product-price">
                {{ item.price }}
              </div>
            </div>

            <div
              v-if="item.mobilePlan?.selectedOffer"
              class="text-static-success-hi caption"
              data-qa="mobile-plan-subsidy"
            >
              {{
                i18n(translations.mobilePlanSubsidy, {
                  partnerName: item.mobilePlan.selectedOffer.partner.label,
                  subsidy: i18n.price(item.mobilePlan.selectedOffer.subsidy),
                })
              }}
            </div>

            <div class="body-2 flex justify-between" data-qa="shipping">
              <div>{{ i18n(translations.shipping) }}</div>
              <div>{{ item.shippingPrice }}</div>
            </div>
          </div>

          <BouyguesMobilePlan
            v-if="
              item.mobilePlan && isBouyguesOffer(item.mobilePlan.selectedOffer)
            "
            class="mt-24"
            data-qa="mobile-plan"
            data-test="bouygues-mobile-plan"
            :offer="item.mobilePlan.selectedOffer"
          />

          <div
            v-if="item.insurance"
            class="body-2 mt-8 flex justify-between"
            data-qa="insurance"
          >
            <div>{{ item.insurance.title }}</div>
            <div>{{ i18n.price(item.insurance.price) }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="withSwap" class="pt-24" data-qa="swap-offer">
      <div class="flex">
        <div
          class="border-static-default-low rounded-sm flex h-48 w-48 items-center justify-center border"
        >
          <div class="flex p-12"><IconSwap /></div>
        </div>
        <div class="ml-16 w-full">
          <div class="flex justify-between">
            <h2 class="body-2-bold">{{ i18n(translations.swap) }}</h2>
            <div class="text-action-success-hi body-2 ml-24">
              {{ swapOffer?.price ? i18n.price(swapOffer?.price) : '' }}
            </div>
          </div>
          <div class="body-2 mt-8 flex justify-between">
            <div>{{ swapOffer?.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <RevDivider class="my-16" />

    <div class="body-2 flex justify-between gap-24">
      <div>{{ i18n(translations.subtotal) }}</div>
      <div class="ml-24">
        {{ subtotal ? i18n.price(subtotal) : '' }}
      </div>
    </div>

    <div v-if="withServiceFee" class="mt-8 flex justify-between">
      <ServiceFeeModal />
      <div class="body-2 ml-24" data-qa="service-fee">
        {{ serviceFee ? i18n.price(serviceFee) : '' }}
      </div>
    </div>

    <div v-if="shouldDisplayTax" class="body-2 mt-8 flex justify-between">
      <div>{{ taxTitle }}</div>
      <div class="ml-24" data-qa="tax">
        {{ taxPrice }}
      </div>
    </div>

    <div v-if="withDiscount" class="mt-8 flex justify-between">
      <RevTag class="uppercase" :label="discountToken" variant="info" />
      <div class="text-action-success-hi body-2 ml-24" data-qa="discount">
        -{{ discountPrice ? i18n.price(discountPrice) : '' }}
      </div>
    </div>

    <div v-if="selectedPaymentMethod">
      <InstallmentSimulation
        :base-price="priceAfterDiscount"
        data-qa="installments"
        :payment-method="selectedPaymentMethod"
      >
        <template #installments="{ isLoading, simulation }">
          <div v-if="!isLoading">
            <div class="body-2 mt-8 flex justify-between">
              <div class="flex">
                <div>{{ i18n(translations.installment) }}</div>
                <PaymentIcons class="ml-1" :methods="[selectedPaymentMethod]" />
              </div>
              <div class="ml-24">
                {{ i18n.price(simulation?.cost || '') }}
              </div>
            </div>

            <RevDivider class="my-16" />

            <div class="body-1-bold mt-8 flex justify-between">
              <div>{{ totalTitle }}</div>
              <div class="ml-24">
                {{ i18n.price(simulation?.totalAmount || '') }}
              </div>
            </div>
          </div>
        </template>
        <template #fallback>
          <div>
            <RevDivider class="my-16" />

            <div class="mt-8 space-y-4">
              <div class="body-1-bold flex justify-between">
                <div>{{ totalTitle }}</div>
                <div class="ml-24" data-qa="price-after-discount">
                  {{ i18n.price(priceAfterDiscount) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </InstallmentSimulation>
    </div>

    <div v-if="!selectedPaymentMethod">
      <RevDivider class="my-16" />

      <div class="mt-8 space-y-4">
        <div class="body-1-bold flex justify-between">
          <div>{{ totalTitle }}</div>
          <div class="ml-24" data-qa="price-after-discount">
            {{ i18n.price(priceAfterDiscount) }}
          </div>
        </div>
      </div>
      <div
        v-if="withSwap && totalPriceAfterBuyback"
        class="text-static-default-low body-2 flex justify-between"
      >
        <RevButtonBase class="underline" @click="openSummaryModal">{{
          i18n(translations.totalPriceAfterBuyback)
        }}</RevButtonBase>
        <span class="ml-24">{{ i18n.price(totalPriceAfterBuyback) }}</span>
      </div>
    </div>

    <div id="checkout-side-cart-submit" />

    <div
      v-if="withMonthlyPayment"
      class="text-static-default-low body-2 mb-8 mt-16"
    >
      {{ i18n(translations.recurring) }}
    </div>

    <CheckoutLegalLinks :with-cancel="withExtendedLegal" />

    <PaymentMethodsWrapper
      v-if="cartStore.isCartLoaded"
      class="mt-32"
      v-bind="paymentMethodsProps"
    />
  </RevCard>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import type { BuybackOffer, MonetaryAmount } from '@backmarket/http-api'
import type { CartItem } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { type PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import InstallmentSimulation from '@backmarket/nuxt-module-payment/advertising/components/InstallmentSimulation/InstallmentSimulation.vue'
import { PaymentIcons } from '@backmarket/nuxt-module-payment/methods'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { openModal } from '@ds/components/ModalBase'
import { RevTag } from '@ds/components/Tag'
import { IconSwap } from '@ds/icons/IconSwap'

import { MODAL_NAMES } from '~/scopes/buyback/constants'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'
import { isBouyguesOffer } from '~/scopes/checkout/utils/isBouyguesOffer'

import { CHECKOUT } from '../../routes-names'
import CheckoutLegalLinks from '../CheckoutLegalLinks.vue'
import ServiceFeeModal from '../ServiceFeeModal.vue'

import translations from './CheckoutSummaryContent.translations'
import BouyguesMobilePlan from './components/BouyguesMobilePlan/BouyguesMobilePlan.vue'
import PaymentMethodsWrapper from './components/PaymentMethodsWrapper/PaymentMethodsWrapper.vue'

const i18n = useI18n()
const route = useRoute()
const {
  'experiment.insuranceMonthlySubscription':
    experimentInsuranceMonthlySubscription,
  'experiment.ppDiscountedSwap': experimentppDiscountedSwap,
} = useExperiments()
const { trackClick } = useTracking()
const cartStore = useCartStore()

const props = withDefaults(
  defineProps<{
    discountPrice?: MonetaryAmount
    discountToken?: string
    items: CartItem[]
    priceAfterDiscount: MonetaryAmount
    selectedPaymentMethod?: PaymentMethod | null
    serviceFee?: MonetaryAmount | null
    subtotal?: MonetaryAmount
    totalPriceAfterBuyback?: MonetaryAmount
    swapOffer?: BuybackOffer | null
    tax?: MonetaryAmount | null
    withDiscount: boolean
    withExtendedLegal: boolean
    withPaymentAvailability: boolean
    withServiceFee: boolean
    withSwap: boolean
    withTax: boolean | null
  }>(),
  {
    discountPrice: undefined,
    discountToken: '',
    selectedPaymentMethod: undefined,
    serviceFee: undefined,
    swapOffer: undefined,
    subtotal: undefined,
    totalPriceAfterBuyback: undefined,
    tax: null,
  },
)
const withMonthlyPayment = computed<boolean>(() => {
  const withMonthlyInsurance = props.items.some((item) =>
    item.insuranceOffers?.some(
      (offer) => offer.selected && !offer.defaultOffer && offer.isMonthly,
    ),
  )

  return (
    experimentInsuranceMonthlySubscription === 'insuranceMonthlyEnabled' &&
    withMonthlyInsurance
  )
})

const paymentMethodsProps = computed(() => ({
  withPaymentAvailability: props.withPaymentAvailability,
  listings: Object.keys(cartStore.availableItemsById),
  bagPrice: cartStore.priceAfterDiscount,
  hasInsurance: cartStore.hasInsurance,
  tokenization: withMonthlyPayment.value,
}))

const formattedItems = computed(() =>
  props.items.map((item) => {
    let quantity = ''

    if (item.quantity > 10) {
      quantity = '+10'
    } else if (item.quantity > 1) {
      quantity = `${item.quantity}`
    }

    return {
      id: item.listingId,
      image: item.image,
      price: i18n.price(item.price),
      shippingPrice:
        item.shippingSelected.sumShippingFeePrice === '0'
          ? i18n(translations.free)
          : i18n.price(item.shippingSelected.sumShippingFeePrice),
      title: item.title,
      insurance: item.insuranceOffers?.find(
        (offer) => offer.selected && !offer.defaultOffer,
      ),
      quantity,
      mobilePlan: item.mobilePlan,
    }
  }),
)

const shouldDisplayTax = computed(() => !props.withTax || !isEmpty(props.tax))

const taxTitle = computed(() =>
  route.name === CHECKOUT.PAYMENT
    ? i18n(translations.tax)
    : i18n(translations.estimatedTax),
)

const taxPrice = computed(() =>
  isEmpty(props.tax) ? i18n(translations.pending) : i18n.price(props.tax),
)

const withMobilePlan = computed(() =>
  formattedItems.value.some(
    (item) => item.mobilePlan && item.mobilePlan.selectedOffer,
  ),
)

const totalTitle = computed(() => {
  if (
    experimentppDiscountedSwap === 'withDiscountedSwap' &&
    (props.withSwap || withMobilePlan.value)
  ) {
    return i18n(translations.totalDueToday)
  }

  return shouldDisplayTax.value
    ? i18n(translations.total)
    : i18n(translations.totalWithTaxes)
})

const openSummaryModal = () => {
  trackClick({
    zone: 'cart_summary',
    name: 'swap_cart_modal',
  })
  openModal(MODAL_NAMES.SWAP_SUMMARY)
}
</script>
